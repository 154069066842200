<template>
  <div
       @click.prevent="handleBackgroundClick"
       class="background"
       id="preorderBackground"
  >
    <MNftUnlock :verifiedEmail="verifiedEmail" />
  </div>
</template>

<script>
import MNftUnlock from '@/components/molecules/m-nft-unlock.vue'

export default {
  name: "OPreorderMenu",
  components: {MNftUnlock},
  props: ["verifiedEmail"],
  methods: {
    handleBackgroundClick: function(e) {
      if (e.target.id !== 'preorderBackground') return
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.background {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
}
</style>