<template>
    <div class="mirror-panel" v-observe-visibility="onResize">
        <div class="video"
             :style="{width: isMobile ? 'inherit' : getCompletionPercentage + '%', height: isMobile ? getCompletionPercentage + '%' : 'inherit'}">
            <video autoplay="autoplay" muted loop playsinline>
                <source :src="getVideo" type="video/webm">
                <source :src="getVideoMp4" type="video/mp4">
            </video>
        </div>
        <div class="edge"></div>
    </div>
</template>

<script>
  import {computed} from 'vue';
  import {useStore} from "vuex";
  import QuestionData from '../../questions';

  export default {
    name: "o-mirror-panel",
    setup() {
      const store = useStore();

      return {
        answers: computed(() => store.state.answers),
        isUlta: computed(() => store.state.isUlta)
      }
    },
    data() {
      return {
        questions: QuestionData,
        screenWidth: Math.max(document.documentElement.clientWidth, window.innerWidth)
      }
    },
    mounted() {
      window.addEventListener("resize", this.onResize);
    },
    methods: {
      onResize() {
        this.$nextTick(() => {
          this.screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
        })
      }
    },
    computed: {
      getVideo() {
        if (this.isUlta) {
          return 'https://igkhair.s3.amazonaws.com/public/UltaQuiz_Video.webm'
        }
        return 'https://igkhair.s3.amazonaws.com/public/4.27+-+5.12+.webm'
      },
      getVideoMp4() {
        if (this.isUlta) {
          return 'https://igkhair.s3.amazonaws.com/public/UltaQuiz_Video.mp4'
        }
        return 'https://igkhair.s3.amazonaws.com/public/4.27+-+5.12+.mp4'
      },
      getCompletionPercentage() {
        let res = Object.keys(this.answers).length / this.getActiveQuestions.length * 100;
        return res <= 10 ? 10 : res;
      },
      getActiveQuestions() {
        // in the event we want to filter these
        return this.questions;
      },
      isMobile() {
        return this.screenWidth <= 1024;
      }
    }
  }
</script>

<style lang="scss" scoped>
    .mirror-panel {
        @apply flex-none;
        height: 30vh;
        width: 100%;
        z-index: 11;
        pointer-events: none;

        /* added for microsite integration */
        position: fixed;
        top: 0;

      @media (min-width: 1024px) {
          position: sticky;
            height: 100vh;
            width: auto;
            @apply flex flex-row;
        }
    }

    .video {
        height: 50%;
        width: 100%;
        overflow: hidden;
        position: relative;
        transition: all .5s ease-in-out;

        @media (min-width: 1024px) {
            height: 100%;
            width: 50%; // dynamically adjust
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // desktop
    }

    .edge {
        position: relative;
        margin-top: -1.5vh;
        width: 100%;
        height: 50px;
        z-index: 100;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("https://igkhair.s3.amazonaws.com/public/tear-mobile.png");

        @media (min-width: 1024px) {
            margin-top: 0;
            margin-left: -4.5rem;
            background: url("https://igkhair.s3.amazonaws.com/public/tear-desktop.png");
            background-size: cover;
            height: 100%;
            width: 103px;
        }
    }
</style>
