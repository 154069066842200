<template>
    <a class="border-solid block vto-button py-2 px-3 border border-solid border-black text-center" @click="change(product.vto_sku)" :alt="product.title">
        <span class="font-bold uppercase">
            Virtual Try On
        </span>
    </a>
</template>

<script>
  export default {
    name: "m-vto-button",
    props: {
      "product": {
        "type": Object,
        "required": true
      }
    },
    data() {
      return {
        event: null,
        closeEvt: null
      }
    },
    mounted() {
      window.YMKRunner = window.YMKRunner || [];
      window.YMKRunner.push(() => {
        this.event = window.YMK.addEventListener('loaded', () => {
          window.YMK.applyMakeupBySku(this.product.vto_sku)
        })

        this.closeEvt = window.YMK.addEventListener('closed', () => {
          this.emitter.emit('ymk-close', true)
        })
      })

    },
    unmounted() {
      if(this.event && window.YMK) {
        window.YMK.removeEventListener(this.event)
        window.YMK.removeEventListener(this.closeEvt)
      }
    },
    methods: {
      change (key) {
        this.$emit('clicked', key)
      }
    }
  }
</script>

<style lang="scss" scoped>
.vto-button {
    background-image: url("https://igkhair.s3.amazonaws.com/public/Holo_CTA.png");
    background-size: cover;
}
</style>
