<template>
    <div class="questions-container" v-if="getActiveQuestions.length > 0" >
        <div class="question-block" v-for="item in getActiveQuestions" :key="item.id" :id="item.id" :style="{paddingTop: (isMobile ? 0.4 * getCompletionPercentage : 0) + 'vh'}">
            <div class="question-inner-wrap">
                <div class="question-title">
                    <div class="question-title-sub"> {{ getIndex(item.id) + 1 }}. {{ item.subtitle }}</div>
                    <div class="question-title-main"> {{ item.title }}</div>
                </div>
                <div class="answer-container">
                    <m-answer v-for="(answer, name) in (item.answers || {})" :key="name" class="question-item"
                              :answer="answer" :question="item" :answer-id="name" />
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
  import MAnswer from '../molecules/m-answer';
  import QuestionData from '../../questions';
  import QuestionDataUlta from '../../questions-ulta';
  import {computed, nextTick} from 'vue'
  import {useStore} from "vuex";

  export default {
    name: "o-quiz-panel",
    components: {MAnswer},
    setup() {
      const store = useStore();

      return {
        answers: computed(() => store.state.answers),
        isUlta: computed(() => store.state.isUlta),
        setComplete: (bool) => store.commit('setComplete', bool)
      }
    },
    data() {
      return {
        questions: this.isUlta ? QuestionDataUlta : QuestionData
      }
    },
    mounted() {
      this.emitter.on('next', (key) => {
        this.markComplete();
        this.nextQuestion(key);
      });

      this.emitter.on('prev', (key) => {
        this.prevQuestion(key);
      })
    },
    methods: {
      isMobile: () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0) <= 1024,
      nextQuestion(key) {
        nextTick(() => {
          const el = document.getElementById(this.findNextQuestionKey(key));
          if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }
        })
      },
      prevQuestion(key) {
        nextTick(() => {
          const el = document.getElementById(this.findNextQuestionKey(key));
          if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }
        })
      },
      findNextQuestionKey(key) {
        return this.findQuestionKey(key, 1);
      },
      findPrevQuestionKey(key) {
        return this.findQuestionKey(key, -1);
      },
      findQuestionKey(key, adjust = 0) {
        let index = this.getIndex(key)
        return (this.getActiveQuestions[index + adjust] || {}).id;
      },
      getIndex (key) {
        return this.getActiveQuestions.findIndex(e => e.id == key);
      },
      markComplete() {
        if(this.isComplete()) {
          setTimeout(() => {
            document.querySelector('.quiz-viewport').scrollTo(0, 0)
          }, 200)
        }


        this.setComplete(this.isComplete());
      },
      isComplete() {
        let active = this.getActiveQuestions;
        return active.length === Object.keys(this.answers).length
      }
    },
    computed: {
      getCompletionPercentage() {
        let res = Object.keys(this.answers).length / this.getActiveQuestions.length * 100;
        return res <= 10 ? 20 : res;
      },
      getActiveQuestions() {
        // in the event we want to filter these
        return this.questions;
      }
    }
  }
</script>

<style lang="scss" scoped>
    .questions-container {
        padding: 5px;
        display: flex;
        flex-direction: column;
        @media (min-width: 1024px) {
            margin-top: 0;
            padding: 30px;
        }
    }

    .question-block {
        min-height: 100vh;
    }

    .question-title {
        font-size: 1.3em;
        font-weight: bold;
        margin: 20px 5px;
        text-transform: uppercase;
        text-align: left;
    }

    .question-title-main {
        font-weight: bold;
        font-size: 2.5rem;
        font-family: 'Founders Grotesk', sans-serif;
        line-height: 2.5rem;
    }

    .question-title-sub {
        font-family: 'Maax Mono', sans-serif;
        font-size: 1.5rem;
    }

    .answer-container {
        padding: 0;
        margin: 0;
        list-style: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row;
        line-height: 30px;
        flex-wrap: wrap;
    }

    .question-item {

    }

    .question-inner-wrap {

    }
</style>
