<template>
    <MModal @close-modal="$emit('close-modal')">
        <template v-slot:body class="text-center">
            <MVtoTile/>
        </template>
    </MModal>
</template>

<script>
  import MModal from "../molecules/m-modal";
  import MVtoTile from "../molecules/m-vto-tile";
  export default {
    name: 'o-vto-modal',
    components: {
      MVtoTile,
      MModal,
    }
  }
</script>

<style scoped>
</style>
