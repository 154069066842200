<template>
    <div class="h-full">
        <m-header>
            <template v-slot:header>DON'T WORRY </template>
            <template v-slot:subtitle class="px-5">It's not you, It's us</template>
        </m-header>
        <div class="edge"></div>
        <div class="main-viewport">
            <div class="main-content">
                <div class="w-full p-4 mb-4 text-center">
                    <div>While we don’t have a color for you, we are working hard on developing additional shades to add to our collection. Stay tuned for our plans!
                        <br><br>
                        Based on your answers we recommend reaching out to a salon professional colorist for a
                        consultation on how to achieve your dream hair.
                    </div>
                </div>
                <div class="">
                    <a class="main-button btn" @click="closeQuizModal(mainUrl)" :href="mainUrl">I'll Take My Chances</a>
                    <a class="secondary-button btn" @click="closeQuizModal(secondaryUrl)" target="_blank" :href="secondaryUrl">
                        {{ !isUlta ? 'Shop IGK Hair Instead' : 'SHOP IGK ON ULTA' }}
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
  import MHeader from "../molecules/m-header";
  import {computed} from 'vue'
  import {useStore} from "vuex";

  export default {
    name: 'o-no-results',
    components: {
      MHeader
    },
    setup() {
      const store = useStore();
      return {
        isUlta: computed(() => store.state.isUlta)
      }
    },
    mounted() {
      document.querySelector('.quiz-viewport').scrollTo(0, 0)
    },
    methods: {
      closeQuizModal(url) {
        if (url === '/') this.emitter.emit('closeQuizModal', true)
        else window.open(url)
      }
    },
    computed:{
      mainUrl ()  {
        return this.isUlta ? 'https://www.ulta.com/brand/igk' : '/'
      },
      secondaryUrl() {
        return this.isUlta ? 'https://www.ulta.com/brand/igk' : 'https://www.igkhair.com/collections/best-sellers'
      }
    }
  }
</script>

<style lang="scss" scoped>
    .main-viewport {
        background: #fff;
        height: 100%;

        .main-content {
            @media (min-width: 1024px) {
                width: 50vw;
                padding-top: 7rem;
            }
            margin: 0 auto;
            padding: 2rem 1rem 1rem;
            height: 100%;
        }

        .btn {
            font-family: 'Founders Grotesk', sans-serif;
            font-weight: 600;
            @apply w-full;
            @media (min-width: 1024px) {
                width: 49%
            }

        }

        .main-button {
            @apply text-center font-bold py-4 px-10 bg-black uppercase text-black inline-block mr-2 mb-3;
            background-image: url("https://igkhair.s3.amazonaws.com/public/Holo_CTA.png");
            background-size: cover;
            background-position: bottom;
            font-family: 'Founders Grotesk', sans-serif;
            font-weight: 600;
            font-size: 1.3rem;
        }

        .secondary-button {
            @apply text-center font-bold py-4 px-10 bg-black uppercase text-white inline-block;
            font-family: 'Founders Grotesk', sans-serif;
            font-weight: 600;
            font-size: 1.3rem;
        }
    }

    .edge {
        position: relative;
        margin-bottom: -4.5vh;
        width: 100%;
        height: 6vh;
        z-index: 100;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("https://igkhair.s3.amazonaws.com/public/tear-mobile.png");
        @media (min-width: 1024px) {
            margin-bottom: -5.7vh;
            background-size: cover;
            background-image: url("https://igkhair.s3.amazonaws.com/public/tear-deaktop-hor2.png");
            height: 12vh;
        }
    }
</style>
