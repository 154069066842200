<template>
  <svg
      width="314"
      height="120"
      viewBox="0 0 83.079165 31.75"
      version="1.1"
      id="svg5"
      inkscape:version="1.1.1 (c3084ef, 2021-09-22)"
      sodipodi:docname="unlock_nft_button.svg"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <g
        inkscape:label="black"
        inkscape:groupmode="layer"
        id="layer1">
      <path
          style="fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="M 6.7617641,6.1969533 82.288416,7.3109919 72.683216,26.8427 2.466682,20.005324 Z"
          id="path857"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer2"
        inkscape:label="purple">
      <path
          style="fill:#f00073;fill-opacity:1;stroke:#000000;stroke-width:0.661458;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          d="M 80.167181,4.3596018 6.8791647,5.1041169 1.0054128,19.737885 72.112032,23.079019 Z"
          id="path1145"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer4"
        inkscape:label="white">
      <path
          style="fill:none;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 1.7401816,20.925832 68.2190134,5.814284 -1.234143,2.08991 z"
          id="path1596" />
      <path
          style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 1.894105,20.55711 66.496406,6.105974 -1.370815,2.67264 z"
          id="path6935" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer3"
        inkscape:label="text">
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.76111px;line-height:1.25;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
          x="16.717388"
          y="14.128627"
          id="text3458"
          transform="rotate(0.86048203)"><tspan
         sodipodi:role="line"
         id="tspan3456"
         style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.76111px;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
         x="16.717388"
         y="14.128627">Unlock to buy</tspan></text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "UnlockNftButton"
}
</script>