<template>
  <svg :id="`cardId${id}_${row}`"
       @click="handleTileClick"
       viewBox="0 0 100 150"
       :style="cssProps"
       ref="rootEl"
  >
    <image :href="imgBack" preserveAspectRatio="xMidYMid slice" x="0" y="0" width="100%" height="100%" />
  </svg>
</template>

<script>

export default {
  name: "MFlipTile",
  props: ['tileHeight', 'data', 'cardMargin', 'row', 'id'],
  emits: ["openTile", "clickTile"],
  computed: {
    cssProps: function() {
      return {
        '--computedMargin': this.cardMargin + 'px',
        '--tileHeight': this.tileHeight
      }
    },
    tileData: function() {
      return this.data;
    },
    imgBack: function() {
      return require(`@/assets/cards/${this.data.img}${this.tileData.type === 'click' ? '' : '_back'}.jpg`)
    }
  },
  methods: {
    handleTileClick: function() {
      if (this.tileData.type === 'click') {
        if (!this.tileData.clickId) return
        this.$emit('clickTile', this.tileData.clickId)
      } else {
        this.$emit('openTile', this.tileData.colorCode, this.$refs.rootEl.getBoundingClientRect())
      }
    },
    isTileDataValid: function() {
      return (
          this.tileData &&
          this.tileData.img &&
          this.tileData.colorCode &&
          this.tileData.colorTitle &&
          this.tileData.id
      )
    }
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  margin: var(--computedMargin);
  height: var(--tileHeight);
  border: 3px solid black;
  border-radius: 1px;
  /* multiple box shadows to create outline: first sets blank area, rest for sides */
  box-shadow:
      8px 8px 0 -4px #fff,
      5px 11px 0 -4px black,
      10px 8px 0 -4px black,
      11px 11px 0 -4px black,
      11px 5px 0 -4px black;
}
</style>
