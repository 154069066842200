<template>
  <div class="panel">
    <h3>Found an IGK NFT?</h3>
    <p>
      If you’ve found one of our NFTs and entered our Color Scavenger Hunt Sweeps, you get exclusive access
      to our upcoming Color collection before it’s released to the public!
    </p>
    <p>
      If you haven't found one yet, keep looking around the web and your favorite social channels for a clue!
    </p>
    <p v-if="verifiedEmail">
      Looks like you've already been confirmed! Click below to access the Color collection!
    </p>
    <p v-else>
      Enter your email address below so that we can confirm your access.
    </p>
    <p v-if="!errorMessage && !verifiedEmail">&nbsp;</p>
    <p class="errorMessage" v-if="errorMessage && !verifiedEmail">{{errorMessage}}</p>
    <input v-if="!verifiedEmail" placeholder="Your Email" v-model="email" />
    <button @click.prevent="checkEmail">Let Me In</button>
  </div>
</template>

<script>
export default {
  name: "MNftUnlock",
  props: ["verifiedEmail"],
  data: function() {
    return {
      email: '',
      errorMessage: '',
      emailRejectedMessage: "Looks like you haven't found any NFTs yet. Keep searching!",
      emailProblemMessage: "Whoops! Looks like something's wrong with the email you entered!",
    }
  },
  watch: {
    email: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.errorMessage = ''
      }
    }
  },
  methods: {
    sendToDefaultPDP: function() {
      window.location.href = 'https://www.igkhair.com/products/perma-color-6r-smoky-scarlet'
    },
    checkEmail: async function() {
      if (this.verifiedEmail) {
        this.sendToDefaultPDP()
      }
      this.errorMessage = ''
      const result = await fetch(`https://nft-api.igkhair.com/api/v1/search?email=${this.email}`, {
        method: 'GET'
      }).then( (res) => {
        if (res.status === 200) {
          return res.json()
        } else {
          console.error(res)
        }
      })
      .then(data => {return data})
      .catch(err => {
        console.error(err)
        this.errorMessage = this.emailProblemMessage
      })
      if (result.available) {
        // expire the cookie after one year
        document.cookie = `nftVerified=${this.email}; max-age=31536000`
        //FIXME send to PLP/collection page when it's ready
        this.sendToDefaultPDP()
      } else {
        this.errorMessage = this.emailRejectedMessage
      }
    },
  }
}

</script>

<style scoped>
.errorMessage {
  color: darkred;
}
* {
  --bgColor: #FF77C5;
}
.panel {
  z-index: 2;
  border: 2px solid black;
  border-radius: 5px;
  width: 288px;
  left: calc(100vw/2 - 288px/2);
  top: calc(100vh/2 - 460px/2);
  position: absolute;
  background-color: var(--bgColor);
  display: flex;
  flex-direction: column;
  box-shadow: 7px 7px black;
}
@media screen and (min-width: 800px) {
  .panel {
    width: 370px;
    left: calc(100vw/2 - 370px/2);
  }
}
h3 {
  font-weight: bold;
  font-size: 28px;
  margin-top: 20px;
  text-transform: uppercase;
}
p {
  text-align: center;
  margin: 5px 11% 10px;
  font-size: 14px;
  line-height: 17.6px;
}
input {
  background-color: inherit;
  border: 2px solid black;
  border-radius: 5px;
  width: 70%;
  margin: auto;
  padding: 1px 4px 3px;
}
input::placeholder {
  padding-left: 5px;
  color: rgba(0,0,0,0.25);
  font-size: 0.8em;
}
button {
  border: 3px solid black;
  background-color: black;
  border-radius: 5px;
  width: 45%;
  height: 15%;
  margin: 17px auto 23px;
  color: white;
  font-weight: bold;
  font-size: 1.15em;
  position: relative;
  transform-style: preserve-3d;
  word-spacing: 2px;
}
button::before {
  content: "";
  position: absolute;
  transform: translateX(-33px) translateY(8px) translateZ(-10px);
  width: 103%;
  height: 103%;
  background-color: var(--bgColor);
  border: 3px solid black;
  border-radius: 5px;
}
@media screen and (max-width: 800px) {
  h3 {
    font-size: 25px;
  }
  p {
    margin: 3%;
  }
  input {
    width: 80%;
  }
  button {
    font-size: 1em;
    margin: 20px auto 20px;
    padding: 15px 0px;
  }
  button::before {
    transform: translateX(-18px) translateY(-7px) translateZ(-10px);
  }
}
</style>
