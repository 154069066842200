<template>
    <div class="recommendations">
        <div class="gallery">
            <header>
                <span class="backBtn" v-on:click="reset()">
                    <i class="fas fa-fw fa-arrow-left"></i> Do the Quiz Again
                </span>
                <h1 class="title">
                    Meet Your Match{{ getRecommendations.length ? 'es' : ''}}
                </h1>
                <span class="subtitle">
                    Now that we know a little about you and your hair-story, let’s find your perfect shade.
                </span>
            </header>
        </div>
        <div class="edge"></div>
        <div class="recommendations-view flex-none md:flex w-full bg-white" v-if="!selectedProduct">
            <MRecommendationItem class="recommendations-item" v-for="(product, key) in padArray(getRecommendations,2)"
                                 :product="product" :key="key"/>
        </div>
        <div class="product-detail bg-white" v-if="selectedProduct">
            <span class="backBtn" v-on:click="selectedProduct = null"><i
                    class="fas fa-fw fa-arrow-left"></i> Go Back</span>
            <MRecommendationInfo class="recommendations-information" :product="getSelectedProduct"/>
        </div>
        <div v-if="false" class="bg-white pt-10">
            <strong>Debug:</strong><br>
            Answers:
            <span v-for="(answer, k) in answers" :key="k">{{ answer }}, </span><br>
            Results: <span v-for="(answer, k) in getRecommendations.map(x => x.id)" :key="k">{{ answer }}, </span><br>
        </div>
    </div>
</template>

<script>
  import {computed} from 'vue';
  import {useStore} from 'vuex';
  import productMap from '../../product-map'
  import MRecommendationItem from "../molecules/m-recommendation-item";
  import MRecommendationInfo from "../molecules/m-recommendation-info";

  export default {
    name: "o-recommendations",
    components: {MRecommendationInfo, MRecommendationItem},
    setup() {
      const store = useStore();
      return {
        resetQuiz: () => store.commit('resetQuiz'),
        setResults: (results) => store.commit('setResults', results),
        matrix: store.state.questions,
        answers: computed(() => Object.values(store.state.answers)),
        isUlta: computed(() => store.state.isUlta),
        maxResults: 4,
        delimiter: '-'
      }
    },
    mounted() {
      this.emitter.on('setProduct', (id) => {
        this.selectedProduct = id
      })
    },
    data() {
      return {
        selectedProduct: null
      }
    },
    methods: {
      getProduct(id) {
        if (!id) {
          return null
        }
        let realId = id.toString().split(this.delimiter);
        return {
          id: id,
          ...productMap[realId[0]]
        }
      },
      reset() {
        this.selectedProduct = null;
        window.scrollTo(0, 0);
        this.resetQuiz()
      },
      padArray(arr, size, fill={}) {
        if(arr.length > size || arr.length === 0) {
          return arr
        }
        return arr.concat(Array(size - arr.length).fill(fill));
      }
    },
    computed: {
      getSelectedProduct() {
        return this.getProduct(this.selectedProduct || '') || {}
      },
      getRecommendations() {
        // Filter example
        Object.filter = (obj, predicate) => {
          return Object.keys(obj)
            .filter(key => predicate(obj[key]))
            .reduce((res, key) => (res[key] = obj[key], res), {});
        };

        console.log('matrix', this.matrix)
        console.log('answers', this.answers)
        let result = Object.filter(this.matrix, (x) => {
          let answersArray = this.answers
            .filter(x => !x.skip)
            .map((val) => {
            if (x[val] === undefined) {
              console.error(val + ' is undefined.');
            }
            return x[val]
          });
          return answersArray.every((r) => r);
        });

        let r = Object.keys(result).slice(0, this.maxResults).map(id => this.getProduct(id));
        //r = this.padArray(r, 4);
        this.setResults(r);

        return r
      }
    }
  }
</script>

<style lang="scss" scoped>
    .backBtn {
        display: block;
        margin: 0 1rem 1rem;
        width: fit-content;
        padding: 20px 10px 0 10px;
        cursor: pointer;
        font-weight: bold;
    }

    .row {
        display: flex;
        width: 100%;
        background: #fff;
        // height: 100%;
    }

    .product-detail {
        width: 100%;
        padding-top: 2rem;
        background: #FFF;
    }

    .row * {
        margin: auto;
    }

    .recommendations {
        width: 100%;
        height: auto;
        text-align: left;
        color: black;
    }

    .recommendations-item {
        width: fit-content;
        padding: 3rem 0;
        margin: 0 auto;
        flex: 1;
    }

    .recommendations-view {
        height: 100%;
        @media (min-width: 1024px) {
            padding-top: 5.7vh;
        }
    }

    .recommendations-information {
        padding: 20px 0;
        margin: 0 auto;
    }

    header {
        color: #000000;
        text-transform: uppercase;
        padding-bottom: 3rem;
        text-align: center;

        h1 {
            text-align: center;
            font-size: 3rem;
            font-family: 'Founders Grotesk', sans-serif;
            font-weight: 700;
        }

        .subtitle {
            font-size: 1.2rem;
            font-family: 'Maax Mono', sans-serif;
        }
    }

    @media screen and (max-width: 1024px) {
        .recommendations-information {
            width: 100%;
        }
    }

    .edge {
        position: relative;
        margin-bottom: -4.5vh;
        width: 100%;
        height: 8vh;
        z-index: 100;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("https://igkhair.s3.amazonaws.com/public/tear-mobile.png");
        @media (min-width: 1024px) {
            margin-bottom: -5.7vh;
            background-size: cover;
            background-image: url("https://igkhair.s3.amazonaws.com/public/tear-deaktop-hor2.png");
            height: 12vh;
        }
    }
</style>
