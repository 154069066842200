<template>
    <MButtonTile v-if="canDisplay(answer)" v-on:click="selectAnswer" class="question-item-answer" :class="{'selected': isActive}" :id="answer.key" :data="answer" />
</template>

<script>
  import MButtonTile from "./m-button-tile";
  import {computed} from 'vue';
  import {useStore} from "vuex";

  export default {
    name: "m-answer",
    components: {
      MButtonTile
    },
    data () {
      return {
        active: false
      }
    },
    props: {
      answer: {
        type: Object,
        required: true,
      },
      answerId: {
        required: true
      },
      question: {
        type: Object,
        required: true
      },
    },
    setup(){
      const store = useStore();

      let answers = computed(() => store.state.answers);

      return {
        answers,
        setAnswer: (key, id) => store.commit('setAnswer', {
            [key]: id
          })
      }
    },
    methods: {
      selectAnswer() {
        // set answer in answer bag
        this.setAnswer(this.question.id, this.answerId);

        this.emitter.emit('next', this.question.id)
      },
      canDisplay(answer) {
        if(answer.depends) {
          return Object.values(this.answers).indexOf(answer.depends) !== -1;
        }

        return true;
      }
    },
    computed: {
      isActive() {
        return Object.values(this.answers).indexOf(this.answerId) > -1;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
