import {createApp} from 'vue'
import App from './App.vue'
import AppQuiz from './AppQuiz'
import {createStore} from 'vuex'
import mitt from 'mitt';

const emitter = mitt();
import './styles/app.scss'; // Here
import VueObserveVisibility from 'vue-observe-visibility'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Create a new store instance.
const store = createStore({
  state() {
    return {
      complete: false,
      answers: {},
      questions: {},
      results: [],
      email: [],
      emailSubmitted: false,
      isUlta: process.env['VUE_APP_ULTA'] || !!(document.referrer.match('ulta.com') || location.toString().match('ulta'))
    }
  },
  mutations: {
    resetQuiz(state) {
      state.complete = false;
      state.answers = {};
      state.results = []
    },
    setComplete(state, payload = false) {
      state.complete = payload
    },
    setResults(state, payload = false) {
      state.results = payload
    },
    setQuestions(state, payload) {
      state.questions = payload
    },
    setAnswer(state, payload) {
      state.answers = {
        ...state.answers,
        ...payload
      }
    }
  }
});

const igkApp = createApp(process.env['VUE_APP_SWITCH'] === 'quiz' ? AppQuiz : App);
igkApp.config.globalProperties.emitter = emitter;
igkApp.use(store)
igkApp.use(VueObserveVisibility)
igkApp.mount('#app')
