<template>
  <svg
      width="314"
      height="120"
      viewBox="0 0 83.079165 31.75"
      version="1.1"
      id="svg5"
      inkscape:version="1.1.1 (c3084ef, 2021-09-22)"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <g
        inkscape:label="black"
        inkscape:groupmode="layer"
        id="layer1"
        sodipodi:insensitive="true">
      <path
          style="fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="M 6.4538906,7.3845425 81.97465,5.924724 73.040476,25.772345 2.6317564,21.331231 Z"
          id="path857"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer2"
        inkscape:label="purple">
      <path
          style="fill:#f00073;fill-opacity:1;stroke:#000000;stroke-width:0.661458;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          d="M 79.754092,3.0473184 1.77149,6.2883406 2.2205571,21.113733 72.341395,22.030308 Z"
          id="path1145"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer4"
        inkscape:label="white">
      <path
          style="fill:none;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 1.9370397,22.275957 68.3775013,3.486671 -1.162222,2.130744 z"
          id="path1596" />
      <path
          style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 2.607478,21.373037 63.490833,4.366049 1.366872,2.717793 z"
          id="path6935"
          sodipodi:nodetypes="cccc" />
      <g
          id="g21871"
          transform="translate(-2.6458334)">
        <g
            style="fill:none"
            id="g8378"
            transform="matrix(0.1829441,-0.0576897,0.0576897,0.1829441,56.745877,9.1949657)">
          <path
              d="M 73.1161,26.3032 67.4549,24.4436 63.4755,16.57099 C 63.3394,16.30064 63.1513,16.05987 62.9218,15.86254 62.6923,15.6652 62.4261,15.51521 62.1384,15.42117 l -8.7566,-2.876348 c -0.2873,-0.09487 -0.5907,-0.13195 -0.8924,-0.10912 -0.3018,0.02283 -0.5961,0.105125 -0.866,0.242146 L 43.7508,16.6573 38.0897,14.79774 c -2.4146,-0.79315 -5.02333,0.52549 -5.81649,2.94012 L 24.363241,41.8185 c -0.793155,2.4146 0.52549,5.0233 2.940119,5.8165 L 62.3298,59.1404 c 2.4146,0.7932 5.0233,-0.5255 5.8164,-2.9401 l 7.91,-24.0807 c 0.7932,-2.4146 -0.5255,-5.0233 -2.9401,-5.8164 z m -26.1423,20.517 c -5.9326,-1.9487 -9.299,-8.6086 -7.3503,-14.5411 1.9487,-5.9326 8.6086,-9.2991 14.5412,-7.3504 5.9326,1.9488 9.299,8.6086 7.3503,14.5412 -1.9487,5.9326 -8.6086,9.2991 -14.5412,7.3503 z"
              fill="#ffffff"
              id="path8369" />
        </g>
        <ellipse
            style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.661458;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            id="path8482"
            cx="68.100975"
            cy="12.865851"
            rx="1.5187659"
            ry="1.515074" />
      </g>
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer3"
        inkscape:label="text">
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-size:7.05556px;line-height:1.25;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Semi-Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
          x="4.5256701"
          y="15.847332"
          id="text3458"
          transform="rotate(-1.0919761)"><tspan
         id="tspan3456"
         style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-size:7.05556px;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Semi-Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
         x="4.5256701"
         y="15.847332">Try it on virtually</tspan></text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "TryOnButton"
}
</script>