<template>
  <div
       @click.prevent="handleBackgroundClick"
       class="background"
       id="preorderBackground"
  >
    <MWelcome />
  </div>
</template>

<script>
import MWelcome from '@/components/molecules/m-welcome.vue'

export default {
  name: "OWelcomePanel",
  components: {MWelcome},
  methods: {
    handleBackgroundClick: function(e) {
      if (e.target.id !== 'preorderBackground') return
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.background {
  display: block;
  position: absolute;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
}
</style>