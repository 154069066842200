<template>
  <div id="dummyBlur" @click.prevent="handleBgClick"></div>
  <svg
    v-if="data"
    id="backgroundSplash"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 200 100"
    @click.prevent="handleBgClick"
  >
    <linearGradient id="splashGradient">
      <stop offset="5%" style="stop-color:#FEA801;" />
      <stop offset="95%" style="stop-color:#FEEA01;" />
    </linearGradient>
    <g id="flipBackground">
      <defs>
        <path id="titlePath" fill="none" stroke="none"
              d="M 0,83 L 220,-3 z"
        />
        <path id="subTitlePath" fill="none" stroke="none"
              d="M 10,107 L 210,54 z"
        />
      </defs>
      <polyline points="-5,65 190,-27 225,15 -5,90" fill="url('#splashGradient')" stroke="none" class="bgEl">
      </polyline>
      <polyline points="-15,105 210,40 210,60 -5,115" fill="url('#splashGradient')" stroke="none" class="bgEl">
      </polyline>
      <text fill="white" class="title splashTitle bgEl">
        <textPath
            href="#titlePath"
            method="stretch"
            startOffset="20"
        >
          {{data.colorTitle}}
        </textPath>
      </text>
      <text fill="white" class="title splashSubTitle bgEl">
        <textPath
            href="#subTitlePath"
            startOffset="40"
        >{{data.colorFull}}</textPath>
      </text>
    </g>
  </svg>
  <svg
      class="card cardFront flipUp"
      @click.prevent="handleCardFlip"
      viewBox="0 0 100 152"
      style="rotateY(180deg);"
      ref="rootEl"
  >
    <image :href="bgSrc" preserveAspectRatio="xMidYMid slice" x="0" y="0" width="100%" />
    <text x="4" y="15" class="title mainTitle">{{data.colorTitle}}</text>
    <text x="4" dy="25" class="title subTitle">{{data.colorFull}}</text>
    <image :href="frontSrc" preserveAspectRatio="xMidYMid slice" x="4" y="30" width="92%"
    />
  </svg>
  <svg
    id="cardBack"
    class="card cardBack flipDown"
    @click="handleCardFlip"
    viewBox="0 0 100 152"
    style="rotateY(0deg);"
    :style="initialPosition"
  >
    <image :href="backSrc" preserveAspectRatio="xMidYMid slice" x="0" y="0" width="100%" />
  </svg>
  <BuyButton :class="{ cardBtn: notSafari, hideButton: !notSafari }" class="buyBtn" @click="handleBuyBtnClick" />
  <TryOnButton v-if="isMobile" :class="{ cardBtn: notSafari, hideButton: !notSafari }" class="vtoBtn" @click=handleVtoBtnClick />
  <TryOnButtonMobile v-else :class="{ cardBtn: notSafari, hideButton: !notSafari }" class="vtoBtn" @click="handleVtoBtnClick" />
  <svg :class="{ cardBtn: notSafari, hideButton: !notSafari }" class="unflipBtn" @click="$emit('close')">
    <image href="@/assets/buttons/unflip_button.svg" />
  </svg>
</template>

<script>

import TryOnButton from '@/assets/buttons/try_on_button.vue'
import TryOnButtonMobile from '@/assets/buttons/try_on_button_mobile.vue'
import BuyButton from '@/assets/buttons/buy_button.vue'

export default {
  name: "MBigTile",
  props: ["data", "launchPos"],
  components: {TryOnButton, TryOnButtonMobile, BuyButton},
  emits: ["close", "clickVto", "clickBuyBtn"],
  data: function() {
    return {
      isCardFlipped: false
    }
  },
  computed: {
    notSafari: function() {
      return navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('FireFox') !== -1
    },
    isMobile: function() {
      return (window && window.innerWidth >= 1024)
    },
    initialPosition: function() {
      return {
        '--initialX': this.launchPos.x + 'px',
        '--initialY': this.launchPos.y + 'px'
      }
    },
    frontSrc: function() {
      return require(`@/assets/cards/${this.data.img}_hair.jpg`)
    },
    backSrc: function() {
      return require(`@/assets/cards/${this.data.img}_back.jpg`)
    },
    bgSrc: function() {
      return require(`@/assets/cards/card_bg.jpg`)
    }
  },
  mounted: function() {
    setTimeout(() => {
      this.handleCardFlip()
    }, 600)
  },
  methods: {
    handleVtoBtnClick: function() {
      this.$emit('clickVto')
    },
    handleBuyBtnClick: function() {
      this.$emit('clickBuyBtn', this.data.colorCode)
    },
    handleCardFlip: function() {
      // safari button display handling
      let safariButtons = document.querySelectorAll('.hideButton')
      if (safariButtons.length > 0 && this.isCardFlipped) {
        setTimeout(() => {
          safariButtons.forEach( el => {
            el.classList.remove('hideButton')
          })
        }, 100)
      } else if (safariButtons.length > 0 && !this.isCardFlipped) {
        safariButtons.forEach( el => {
          el.classList.remove('hideButton')
        })
      }

      let front = document.querySelector('.card.cardFront')
      let back = document.querySelector('.card.cardBack')
      let bgEls = document.querySelectorAll('.bgEl')
      let btnEls = document.querySelectorAll('.cardBtn')

      if (this.isCardFlipped) {
        front.classList.remove('flipDown')
        front.classList.add('flipUp')
        back.classList.remove('flipUp')
        back.classList.add('flipDown')
        btnEls.forEach(el => {
          if (this.notSafari) {
            el.classList.remove('splashWipe')
          }
        })
      } else {
        front.classList.remove('flipUp')
        front.classList.add('flipDown')
        back.classList.remove('flipDown')
        back.classList.add('flipUp')
        bgEls.forEach(el => {
          el.classList.add('splashWipe')
        })
        btnEls.forEach(el => {
          if (this.notSafari) {
            el.classList.add('splashWipe')
          }
        })
      }
      this.isCardFlipped = !this.isCardFlipped
    },
    handleBgClick: function() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.hideButton {
  display: none;
}
#mobile-close-btn {
  display: none;
}
@media screen and (max-width: 1023px) {
  #mobile-close-btn {
    display: block;
    position: fixed;
    left: 4%;
    top: 6%;
    height: 51px;
    z-index: 10;
  }
}
#tryOnBtn {
  cursor: pointer;
  position: absolute;
  left: 550px;
  top: 470px;
  transform: rotate(25deg) scale(0.8);
  box-sizing: border-box;
  z-index: 10;
}
.cardBtn {
  clip-path: inset(0px 2000px 0px 0px);
}
.unflipBtn {
  cursor: pointer;
  position: fixed;
  left: calc(100vw/2 + 20px);
  top: 5%;
  transform: rotate(10deg) scale(0.8);
  box-sizing: border-box;
  z-index: 10;
}
.buyBtn {
  cursor: pointer;
  position: fixed;
  left: calc(100vw/2 + 8px);
  top: 470px;
  transform: rotate(25deg) scale(0.8);
  box-sizing: border-box;
  z-index: 11;
  height: 101px
}
.vtoBtn {
  cursor: pointer;
  position: fixed;
  left: calc(100vw/2 + 17px);
  top: 405px;
  transform: rotate(15deg) scale(0.85);
  box-sizing: border-box;
  z-index: 10;
  height: 101px;
}
@media screen and (max-width: 1023px) {
  .unflipBtn {
    left: calc(100vw/2 + 50px);
  }
}
#dummyBlur {
  /* empty element to blur the whole background before wipe animation */
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(5px)
}
#cardBack {
  animation-name: zoomin;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}
.bgEl {
  /* initial state of background splash elements, before animate in */
  clip-path: inset(0px 2000px 0px 0px);
}
.splashWipe {
  /* not directly assigned in template, applied with js */
  animation-duration: 0.5s;
  animation-name: splashWipe;
  animation-delay: 0.1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
@keyframes zoomin {
  /* these vars attached to DOM through computed style property in script above */
  from {
    top: calc(var(--initialY) - 150px);
    left: calc(var(--initialX) - 100px);
    box-shadow: none;
    border: none;
    transform: scale(0.2);
  }
  to {
    transform: scale(1);
  }
}
@keyframes splashWipe {
  from {
    clip-path: inset(0px 2000px 0px 0px);
  }
  to {
    clip-path: inset(0px);
  }
}
.title {
  text-transform: uppercase;
}
.mainTitle {
  font-size: 0.6em;
  font-family: 'Founders Grotesk', sans-serif;
  font-weight: 700;
}
.subTitle {
  font-size: 0.35em;
  font-family: 'Founders Grotesk', sans-serif;
}
#backgroundSplash {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
@media screen and (max-width: 1023px) {
  /* don't show on mobile, mobile microsite has it's own splash svg with different attributes */
  #backgroundSplash {
    display: none;
  }
}
svg.card {
  position: fixed;
  z-index: 10;
  /* transform: rotate(3deg); */
  width: 300px;
  top: 10%;
  /* I know 300px is a magic number, it's easier than wrangling the on-page width in here programmatically */
  left: calc(100vw - 100vw/2 - 300px/2);
  box-shadow: 10px 10px black;
  border-bottom: 2px solid black;
  border-radius: 2px;
  /* flip */
  transition: transform 0.4s;
  transition-timing-function: ease-in;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.title {
  color: white;
  text-transform: uppercase;
}
.splashTitle {
  font-weight: 700;
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 1.35em;
}
.splashSubTitle {
  font-family: 'Founders Grotesk', sans-serif;
  font-size: 0.5em;
}
.flipUp {
  transform: rotateY(180deg);
}
.flipDown {
  transform: rotateY(0deg);
}

@media screen and (min-width: 1024px) {
  /* we don't do the little rotate on mobile, not enough space */
  svg.card {
    /* transform: rotate(3deg); */
    /* width: fit-content; */
    /* see note above about magic numbers */
    left: calc(100vw - 100vw/2 - 200px);
  }
}
</style>
