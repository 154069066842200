<template>
  <div class="appContainer">
    <div
        class="cardRow"
        v-for="(row, idx) in tileRows"
        :key="idx"
        :id="`cardRow${idx}`"
        @scroll="handleRowScroll(idx)"
    >
      <MFlipTile
          v-for="(card, idy) in row"
          :key="idy"
          :row="idx"
          :data="card"
          :id="idy"
          class="flipTile"
          tileHeight="27vh"
          :cardMargin="cardMargin"
          @openTile="handleTileOpen"
          @clickTile="handleTileClick"
      />
    </div>
    <MBigTile
        v-if="currentBigCard > -1"
        :data="cardData[currentBigCard]"
        :launchPos="zoomStartRect"
        @close="closeEverything"
        @clickVto="handleVtoClick"
        @clickBuyBtn="handleBuyBtnClick"
    />
    <OPreorderMenu
        v-if="openPanel === 'nft'"
        :verifiedEmail="verifiedEmail"
        @close="closeEverything"
        @updateEmail="handleUpdateEmail"
    />
    <div class="quizContainer" id="quiz-container" v-if="openPanel === 'quiz'" @click="handleQuizClose">
      <Quiz />
    </div>
    <OVtoPopup
        v-if="openPanel === 'vto'"
        :verifiedEmail="verifiedEmail"
        @close="closeEverything"
        @openNftUnlock="handleSwitchToNft"
        @clickBuyBtn="handleBuyBtnClick"
    />
    <OWelcomePanel :verifiedEmail="verifiedEmail" v-if="openPanel === 'welcome'" @close="closeEverything" />
  </div>
</template>

<script>
// plan on ~25 colors for now, 3 rows, roughly 8 per row, 7 visible
// - this is going to need some more work, for example: what if a row has less than 8 items? presently the exact
//   number of cards per row is uncontrolled, it's just set to 3 rows
// - use number of items per row and card width (multiply) combined with viewport width to figure out if the number
//   of cards will overflow or not, and add a second copy of the row, or part of it, so there's enough to overflow
//   - be careful of removing/adding as soon as the card exits, that will change the order if the total list is much
//     larger than the viewport, unless I specifically only move the first and last children on exit/entry (this is
//     the way I think)
import MFlipTile from '@/components/molecules/m-flip-tile.vue'
import MBigTile from '@/components/molecules/m-big-tile.vue'
import OPreorderMenu from '@/components/organisms/o-preorder-menu.vue'
import Quiz from '@/views/Quiz.vue'
import importedCardData from '@/assets/cardData.json'
import productMap from '@/../src/product-map.json'
import clickTileData from '@/assets/clickCardData.json'
import OVtoPopup from '@/components/organisms/o-vto-popup.vue';
import OWelcomePanel from '@/components/organisms/o-welcome-panel.vue';

export default {
  name: "Microsite",
  components: {MFlipTile, MBigTile, OPreorderMenu, Quiz, OVtoPopup, OWelcomePanel},
  data() {
    return {
      devDisablePopups: false,
      cardData: importedCardData,
      tileRows: [],
      cardMargin: 20,
      autoScrollTimers: [null, null, null],
      autoScrollState: true,
      isTileOpen: false,
      currentBigCard: -1,
      zoomStartRect: {},
      openPanel: '',
      verifiedEmail: ''
    }
  },
  beforeMount: function() {
    // randomize card order
    for (let i = 0; i < 3; i++) {
      this.tileRows[i] = this.randomizeCardOrder(6)
    }
    this.handleUpdateEmail()
  },
  mounted: function() {
    // start rows scrolled halfway so there's room to move on both sides
    for (let i = 0; i < this.tileRows.length; i++) {
      let el = document.getElementById(`cardRow${i}`)
      el.scrollLeft = el.scrollWidth / 2
    }
    if (window) {window.addEventListener('keyup', this.handleEscapeKey)}


    this.setupAutoScroll()

    this.emitter.on('closeQuizModal', () =>{
      this.closeEverything();
    })
  },
  beforeUnmount: function() {
    this.autoScrollTimers.forEach(timerRef => {
      clearInterval(timerRef)
    })
    if (window) {window.removeEventListener('keyup', this.handleEscapeKey)}
  },
  watch: {
    autoScrollState: function(newVal) {
      if (!newVal) this.stopAllAutoScroll()
      else this.setupAutoScroll()
    }
  },
  methods: {
    getCookieEmail: function() {
      let hasVerifiedEmail = document.cookie.split('; ').find(row => row.startsWith('nftVerified='))
      if (hasVerifiedEmail) return hasVerifiedEmail.split('=')[1]
      else return ''
    },
    handleUpdateEmail: function() {
      // make sure we have the latest email from cookies
      this.verifiedEmail = this.getCookieEmail()
    },
    handleBuyBtnClick: function(colorCode) {
      if (this.verifiedEmail) {
        window.location.href = productMap[colorCode].url
      } else {
        this.closeEverything(false)
        this.openPanel = 'nft'
      }
    },
    closeEverything: function(startScroll = true) {
      this.openPanel = ''
      this.currentBigCard = -1
      if (startScroll) {
        this.setupAutoScroll()
      }
    },
    handleSwitchToNft: function() {
      this.closeEverything(false)
      this.openPanel = 'nft'
    },
    handleEscapeKey: function(e) {
      if (e.key === 'Escape') {
        this.closeEverything()
      }
    },
    handleVtoClick: function() {
      this.closeEverything(false)
      this.openPanel = 'vto'
    },
    knuthShuffle: function(array) {
      let curIdx = array.length, randIdx;
      while (curIdx > 0) {
        randIdx = Math.floor(Math.random() * curIdx);
        curIdx--;
        [array[curIdx], array[randIdx]] = [array[randIdx], array[curIdx]]
      }
      return array
    },
    randomizeCardOrder: function(spacing) {
      // returns a copy of flip tile and click tile data, in a random order, with click tiles every 'spacing'
      //   flip tiles

      // first we knuth shuffle the flip cards and click cards
      let shuffledArray = this.knuthShuffle(Array.from(importedCardData))
      let clickCards = this.knuthShuffle(Array.from(clickTileData))
      if (process.env.VUE_APP_NFT_AVAILABLE !== 'true') {
        // remove nft tile from clickCards if disabled in .env
        clickCards = clickCards.filter(obj => obj.clickId !== 'nft')
      }
      if (process.env.VUE_APP_VTO_AVAILABLE !== 'true') {
        // remove vto from clickCards if disabled in .env
        clickCards = clickCards.filter(obj => obj.clickId !== 'vto')
      }

      // finally we insert one click card in the shuffled order every 'spacing' tiles, repeating as necessary
      let clickIdx = 0
      const limit = shuffledArray.length + clickCards.length
      let lastTile = ''

      for (let i = 0; i < limit; i++) {
        if (i % spacing === 0) {
          let curId = clickCards[clickIdx].clickId
          // don't repeat click tiles
          if (curId === lastTile) {
            clickIdx++
          }

          shuffledArray = [...shuffledArray.slice(0,i), clickCards[clickIdx], ...shuffledArray.slice(i)]
          lastTile = clickCards[clickIdx].clickId
          clickIdx++;
        }
        // reset click index to go around again
        if (clickIdx >= clickCards.length) clickIdx = 0
      }

      return shuffledArray
    },
    handleQuizClose: function(e) {
      if (e.target.id !== 'quiz-container') return
      this.closeEverything()
    },
    handleTileClick: function(id) {
      this.openPanel = id
      this.stopAllAutoScroll()
    },
    handleTileOpen: function(colorCode, rect) {
      this.currentBigCard = this.cardData.findIndex(obj => obj.colorCode === colorCode)
      this.zoomStartRect = rect
      this.stopAllAutoScroll()
    },
    handleRowScroll: function(idx) {
      const row = document.getElementById(`cardRow${idx}`)

      // this is just for getting the cards' final width on the page
      let testNodeForWidth = document.getElementById(row.lastElementChild.id)
      let width = testNodeForWidth.getBoundingClientRect().width + this.cardMargin*2
      let scrollPartial = row.scrollLeft / (row.scrollWidth - width)

      if (scrollPartial < 0.2) {
        let width = row.lastElementChild.getBoundingClientRect().width + (this.cardMargin*2)
        let moveChild = row.removeChild(row.lastElementChild)
        if (moveChild.childNodes.length <= 0) return
        row.prepend(moveChild)
        row.scrollLeft += width

      } else if (scrollPartial > 0.6) {
        let width = row.firstElementChild.getBoundingClientRect().width + (this.cardMargin*2)
        let moveChild = row.removeChild(row.firstElementChild)
        if (moveChild.childNodes.length <= 0) return
        row.append(moveChild)
        row.scrollLeft -= width
      }
    },
    setupAutoScroll() {
      this.stopAllAutoScroll()
      // if user has prefers-reduced-motion setting, don't auto-scroll
      const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
      if (!mediaQuery || mediaQuery.matches) return;

      /* eslint-disable no-unused-vars */
      if (!this.autoScrollState) return
      this.tileRows.forEach( (val, idx) => {
        let moveAmount = 1
        // reverse middle row
        if (idx % 2 !== 0) moveAmount = -1
        this.autoScrollTimers[idx] = this.startAutoScroll(idx, moveAmount)
      })
    },
    startAutoScroll(idx, moveAmount) {
      try {
        return setInterval(() => {
          document.getElementById(`cardRow${idx}`).scrollLeft += moveAmount
        }, 50)
      } catch (err) {
        console.error(moveAmount)
      }
    },
    stopAllAutoScroll() {
      this.stopAutoScroll(0)
      this.stopAutoScroll(1)
      this.stopAutoScroll(2)
    },
    stopAutoScroll(idx) {
      clearInterval(this.autoScrollTimers[idx])
    }
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>

<style scoped>
.quizContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
}
.quizContainer > * {
  position: fixed;
  width: 90vw;
  height: 90vh;
  left: calc(100vw/2 - 90vw/2);
  top: calc(100vh/2 - 90vh/2);
  box-shadow: 10px 10px 0 -4px black;
  overflow-y: hidden;
}
.cardRow {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-height: 350px;
  /* Firefox hide scrollbar */
  scrollbar-width: none;
}
.cardRow::-webkit-scrollbar {
  /* Safari & Chrome hide scrollbar */
  display: none;
}
#flipbackground {
  width: 100%;
  height: 100%;
}
.appContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  /*background-color: #C3FA84;*/
  background-image: url('~@/assets/images/Holo.jpg');
  background-size: cover;
  font-family: "FoundersGroteskWeb-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
