<template>
    <div class="quantity-toggle flex flex-nowrap justify-between">
        <button class="border border-solid border-black px-4 py-3" @click="decrement()">&mdash;</button>
        <label class="flex-grow">
            <span class="sr-only">Quantity</span>
            <input class="w-full text-center px-4 py-4 border-t border-b border-black border-solid" type="text" :value="modelValue"
                   @input="$emit('update:modelValue', $event.target.value)" readonly/>
        </label>
        <button class="border border-solid border-black px-4 py-3" @click="increment()">&#xff0b;</button>
    </div>
</template>

<script>
  export default {
    name: "m-quantity-selector",
    props: {
      product: {
        type: Object,
        required: true
      },
      modelValue: {
        type: Number,
        default: 1
      }
    },
    data () {
      return {
        quantity: 1
      }
    },
    methods: {
      increment () {
        this.$emit('update:modelValue', this.modelValue + 1);
      },
      decrement () {
        if(this.modelValue !== 1) {
          this.$emit('update:modelValue', this.modelValue - 1);
        }
      }
    },
    computed: {
      inputVal: {
        get() {
          return this.modelValue;
        },
        set(val) {
          this.$emit('update:modelValue', val);
        }
      }
    }
  }
</script>

<style scoped>

</style>
