<template>
  <div class="panel">
    <h1>IGK COLOR</h1>
    <h1 class="bottomHeader">PERMANENT COLOR KIT</h1>
    <h2>Salon Born. Colorist Approved.</h2>
    <h2 class="bottomHeader">Next generation at-home hair color.</h2>
      <ul>
        <li>25 signature shades, from traditional to fun, to reinvent your hair.</li>
        <li>15x shinier* and 88% softer and smoother strands with 40% reduced breakage**</li>
        <li>Featuring hair-nourishing Squalane, Orchid and Bamboo Extract</li>
        <li>Vegan. Cruelty-free. Gluten-free. Ammonia-free.</li>
      </ul>
    <p class="finePrint">
      * based on the results achieved on shade 1B: results may vary per shade; results compared to untreated control.
    </p>
    <p class="finePrint">
      ** compared to untreated control
    </p>
  </div>
</template>

<script>
export default {
  name: "MWelcome",
}

</script>

<style scoped>
* {
  color: #000;
}
h1, h2 {
  line-height: 0.9em;
}
.bottomHeader {
  margin-bottom: 12px;
}
.finePrint {
  font-size: 10px;
  text-align: left;
  line-height: 0.95em;
  margin-bottom: 10px;
  padding-left: 0.7em;
  text-indent: -0.7em;
}
.finePrint:first-of-type {
  margin-top: 20px;
}
.panel {
  z-index: 2;
  border: 2px solid black;
  border-radius: 5px;
  width: 288px;
  left: calc(100vw/2 - 288px/2);
  top: calc(100vh/8);
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 7px 7px black;
  padding-bottom: 15px;
  background-color: #b3fd72;
}
@media screen and (min-width: 800px) {
  .panel {
    width: 370px;
    left: calc(100vw/2 - 370px/2);
  }
}
.panel > ul {
  text-align: left;
  list-style-type: disc;
}
li {
  margin: 8px 25px auto 60px;
  font-size: 14px;
}
h1 {
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
}
h1:first-child {
  margin-top: 20px;
}
h2 {
  font-size: 23px;
}
p {
  text-align: center;
  margin: 5px 8% 0 8%;
  font-size: 12px;
  line-height: 21.6px;
}
input {
  background-color: inherit;
  border: 2px solid black;
  border-radius: 5px;
  width: 70%;
  margin: auto;
  padding: 1px 4px 3px;
}
input::placeholder {
  padding-left: 5px;
  color: rgba(0,0,0,0.25);
  font-size: 0.8em;
}
button {
  border: 3px solid black;
  background-color: black;
  border-radius: 5px;
  width: 45%;
  height: 15%;
  margin: 5px auto 25px;
  color: white;
  font-weight: bold;
  font-size: 1.15em;
  position: relative;
  transform-style: preserve-3d;
  word-spacing: 2px;
}
button::before {
  content: "";
  position: absolute;
  transform: translateX(-33px) translateY(-1px) translateZ(-10px);
  width: 103%;
  height: 103%;
  background-color: var(--bgColor);
  border: 3px solid black;
  border-radius: 5px;
}
@media screen and (max-width: 800px) {
  h3 {
    font-size: 25px;
  }
  p {
    margin: 3%;
  }
  input {
    margin-top: 20px;
    width: 80%;
  }
  button {
    font-size: 1em;
    margin: 20px auto 20px;
    padding: 15px 0px;
  }
  button::before {
    transform: translateX(-18px) translateY(-7px) translateZ(-10px);
  }
}
</style>
