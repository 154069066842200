<template>
  <svg
      width="300"
      height="100"
      viewBox="0 0 79.374997 26.458334"
      version="1.1"
      id="svg5"
      inkscape:version="1.1.1 (c3084ef, 2021-09-22)"
      sodipodi:docname="buy_button.svg"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <g
        inkscape:groupmode="layer"
        id="layer2"
        inkscape:label="black bg"
        sodipodi:insensitive="true">
      <path
          style="fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.286111px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="M 4.1214065,9.5182803 73.779852,4.2353104 69.223235,23.55957 6.5921046,19.986731 Z"
          id="path1535-5"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:label="Layer 1"
        inkscape:groupmode="layer"
        id="layer1"
        sodipodi:insensitive="true">
      <path
          style="fill:#f00073;fill-opacity:1;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="M 2.7389758,6.767312 68.625297,2.0719246 65.219267,20.657832 5.8692339,18.505779 Z"
          id="path1535"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer3"
        inkscape:label="white tri"
        sodipodi:insensitive="true">
      <path
          style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 6.6517985,20.346678 57.5184935,2.738976 -0.978207,2.934617 z"
          id="path1899" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer4"
        inkscape:label="text">
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.05556px;line-height:1.25;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583"
          x="16.983105"
          y="15.538044"
          id="text19788"
          transform="rotate(-3.0045114)"><tspan
         sodipodi:role="line"
         id="tspan19786"
         style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.05556px;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold';fill:#ffffff;fill-opacity:1;stroke-width:0.264583"
         x="7.983105"
         y="15.538044">Buy Now</tspan></text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "BuyButton"
}
</script>
