<template>
    <div class="quiz-viewport-wrapper">
        <div class="quiz-viewport" :class="getClasses()">
            <o-mirror-panel v-show="!isComplete && !isEmptyResults" class="row quiz-mirror"></o-mirror-panel>
            <o-quiz-panel v-show="!isComplete && !isEmptyResults" class="row quiz"></o-quiz-panel>
            <o-recommendations v-show="isComplete && !isEmptyResults"></o-recommendations>
            <o-no-results v-if="isEmptyResults"/>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
  import '../styles/quiz.scss';
  import OMirrorPanel from "../components/organisms/o-mirror-panel";
  import OQuizPanel from "../components/organisms/o-quiz-panel";
  import ORecommendations from "../components/organisms/o-recommendations";
  import {computed} from 'vue';
  import {useStore} from "vuex";
  import questionMatrix from '../quiz-matrix'
  import ultaQuestionMatrix from '../quiz-matrix-ulta'
  import ONoResults from "../components/organisms/o-no-results";

  export default {
    name: "Quiz",
    components: {
      ONoResults,
      ORecommendations,
      OQuizPanel,
      OMirrorPanel
    },
    beforeMount () {
      if(this.isUlta) {
        this.setQuestions(ultaQuestionMatrix);
       } else {
        this.setQuestions(questionMatrix);
      }

    },
    setup() {
      const store = useStore();
      return {
        isUlta: computed(() => store.state.isUlta),
        isComplete: computed(() => store.state.complete),
        isEmptyResults: computed(() => Object.keys(store.state.answers).length > 0 && store.state.results.length === 0),
        results: computed(() => store.state.results),
        answers: computed(() => store.state.answers),
        setQuestions: (data) => store.commit('setQuestions', data)
      }
    },
    methods: {
      getClasses() {
        return window.location !== window.parent.location ? 'iframe-shim' : 'no-iframe'
      }
    }
  }
</script>

<style lang="scss" scoped>

    .iframe-shim {
        padding-bottom: 200px;
    }
    .quiz {
        z-index: 10;
        position: relative;
    }
    .quiz-viewport {
        position: relative;
        //background: #AACDDB url("https://igkhair.s3.amazonaws.com/public/Overlay_Texture_Mobile.png") fixed;
        overflow-y: scroll;
        min-height: 100vh;
        height: 100%;
    }
    /* this query added for microsite integration */
    @media (max-width: 1023px) {
      o-quiz-panel {
        overflow-y: scroll;
      }
      quiz-viewport {
        overflow-y: hidden;
      }
    }

    .quiz-viewport-wrapper {
        background: #AACDDB url("https://igkhair.s3.amazonaws.com/public/Holograph_Background_Mobile.jpg") fixed;
        background-size: cover;
        height: 100%;

        @media (min-width: 1024px) {
            background: #AACDDB url("https://igkhair.s3.amazonaws.com/public/Holograph_Background.jpg") fixed;
            background-size: cover;
        }
    }

    @media (min-width: 1024px) {


        .quiz-viewport {
            scroll-behavior: smooth;

             //background: #AACDDB url("https://igkhair.s3.amazonaws.com/public/Overlay_Texture_Desktop.png") fixed;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            min-height: 100vh;
            height: 100%;
            overflow-y: auto;

            @media (min-width: 1024px) {

            }

          /* removed these for integration into microsite
            min-height: 100vh;
            height: 100%;

           */

            .row {
                flex: 1 0 50%;
            }
        }
        o-mirror-panel {
            flex: 1 0 50%;
        }
        o-quiz-panel {
            flex: 1 0 50%;
        }
    }
</style>
