<template>
    <div class="modal fade" style="display: block; opacity:1" @click="closeModal">
        <div class="modal-dialog">
            <div class="modal-content" @click.stop>
                <div class="modal-header">
                    <slot name="header"></slot>
                    <button type="button" class="close float-right">
                        <span @click="closeModal">&times; Close</span>
                    </button>
                    <div class="clearfix"></div>
                </div>
                <div class="modal-body text-center">
                    <slot name="body"></slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'm-modal',
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
    },
    mounted() {
      // Unicode key code of the escape key that is triggered when the key is pressed
      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.closeModal();
        }
      });
    },
  }
</script>

<style lang="scss" scoped>
    .modal {
        position:fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width: 100%; /*otherwise div defaults to page width*/
        margin: 0 auto; /*horizontally centers div*/
        z-index: 200;
        background: rgba(255,255,255, 0.5);

        .modal-dialog {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        .modal-content {
            width: 100vw;
            height: 100vh;
            overflow: scroll;
            background: white;
            padding: 1rem;

            @media (min-width: 1024px) {
                width: auto;
                height: auto;
            }
        }
    }
</style>
