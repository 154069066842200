<template>
  <div class="scrollContainer">
  <div class="appContainer" @scroll="handleContainerScroll">
    <svg id="backgroundSplash" preserveAspectRatio="xMidYMid" viewBox="0 0 100 200" v-if="currentBigCard > -1"
         @click.prevent="closeEverything"
     >
      <linearGradient id="splashGradient">
        <stop offset="5%" style="stop-color:#FEA801;" />
        <stop offset="95%" style="stop-color:#FEEA01;" />
      </linearGradient>
      <g id="flipBackground">
        <defs>
          <path id="titlePath" fill="none" stroke="none"
                d="M -15,160 L 130,-3 z"
          />
          <path id="subTitlePath" fill="none" stroke="none"
                d="M -20,209 L 140,100 z"
          />
        </defs>
        <polyline points="-15,125 115,-30 125,25 -15,175" fill="url('#splashGradient')" stroke="none"></polyline>
        <polyline points="-15,190 120,100 120,130 -15,210" fill="url('#splashGradient')" stroke="none"></polyline>
        <text fill="white" class="title splashTitle">
          <textPath
              href="#titlePath"
              method="stretch"
              startOffset="20"
          >{{cardData[currentBigCard].colorTitle}}</textPath>
        </text>
        <text fill="white" class="title splashSubTitle">
          <textPath
              href="#subTitlePath"
              startOffset="40"
          >{{cardData[currentBigCard].colorFull}}</textPath>
        </text>
      </g>
    </svg>
    <div
        class="cardRow"
        v-for="(row, idx) in tileRows"
        :key="idx"
        :id="`cardRow${idx}`"
        @scroll="handleRowScroll(idx)"
    >
      <MFlipTile
          v-for="(card, idy) in row"
          :key="idy"
          :row="idx"
          :data="card"
          :id="idy"
          class="flipTile"
          tileWidth="85%"
          :cardMargin="cardMargin"
          @openTile="handleTileOpen"
          @clickTile="handleTileClick"
      />
    </div>
    <MBigTile
        v-if="currentBigCard > -1"
        :data="cardData[currentBigCard]"
        :launchPos="zoomStartRect"
        @close="closeEverything"
        @clickVto="handleVtoClick"
        @clickBuyBtn="handleBuyBtnClick"
    />
    <OPreorderMenu
        v-if="openPanel === 'nft'"
        :verifiedEmail="verifiedEmail"
        @close="closeEverything"
        @updateEmail="handleUpdateEmail"
    />
    <div class="quizContainer" id="quiz-container" v-if="openPanel === 'quiz'" @click.prevent="handleQuizClose">
      <Quiz />
      <svg id="quiz-close-btn" viewBox="0 0 50 50" @click.prevent="closeEverything">
        <circle cx="25" cy="25" r="25" fill="black"></circle>
        <line x1="15" y1="15" x2="35" y2="35" stroke="white" stroke-width="5px" stroke-linecap="round"></line>
        <line x1="15" y1="35" x2="35" y2="15" stroke="white" stroke-width="5px" stroke-linecap="round"></line>
      </svg>
    </div>
    <OVtoPopup
        v-if="openPanel === 'vto'"
        :verifiedEmail="verifiedEmail"
        @close="closeEverything"
    />
    <OWelcomePanel :verifiedEmail="verifiedEmail" v-if="openPanel === 'welcome'" @close="closeEverything" />
  </div>
  </div>
</template>

<script>
import MFlipTile from '@/components/molecules/m-flip-tile.vue'
import MBigTile from '@/components/molecules/m-big-tile.vue'
import OPreorderMenu from '@/components/organisms/o-preorder-menu.vue'
import Quiz from '@/views/Quiz.vue'
import importedCardData from '@/assets/cardData.json'
import clickTileData from '@/assets/clickCardData.json'
import productMap from '@/../src/product-map.json'
import OVtoPopup from '@/components/organisms/o-vto-popup.vue';
import OWelcomePanel from '@/components/organisms/o-welcome-panel.vue';

export default {
  name: "MobileMicrosite",
  components: {MFlipTile, MBigTile, OPreorderMenu, Quiz, OVtoPopup, OWelcomePanel},
  data() {
    return {
      cardData: importedCardData,
      tileRows: [0,1],
      cardMargin: 10,
      isTileOpen: false,
      currentBigCard: -1,
      scrollDir: 1,
      prevScrollPos: 0,
      zoomStartRect: {},
      verifiedEmail: '',
      openPanel: ''
    }
  },
  beforeMount: function() {
    // randomize card order
    this.tileRows[0] = this.randomizeCardOrder(5)
    this.tileRows[1] = this.randomizeCardOrder(5, 2)
    this.handleUpdateEmail()
  },
  mounted: function() {
    // start container scrolled halfway so there's room to move up and down
    let el = document.querySelector(".appContainer")
    el.scrollTop = el.scrollHeight / 2

    this.prevScrollPos = el.scrollTop
    this.emitter.on('closeQuizModal', () => {
      this.closeEverything(false)
    })
  },
  methods: {
    getCookieEmail: function() {
      let hasVerifiedEmail = document.cookie.split('; ').find(row => row.startsWith('nftVerified='))
      if (hasVerifiedEmail) return hasVerifiedEmail.split('=')[1]
      else return ''
    },
    handleUpdateEmail: function() {
      // make sure we have the latest email from cookies
      this.verifiedEmail = this.getCookieEmail()
    },
    handleBuyBtnClick: function(colorCode) {
      if (this.verifiedEmail) {
        window.location.href = productMap[colorCode].url
      } else {
        this.closeEverything()
        this.openPanel = 'nft'
      }
    },
    handleVtoClick: function() {
      this.closeEverything()
      this.openPanel = 'vto'
    },
    closeEverything: function() {
      this.openPanel = ''
      this.currentBigCard = -1
    },
    knuthShuffle: function(array) {
      let curIdx = array.length, randIdx;
      while (curIdx > 0) {
        randIdx = Math.floor(Math.random() * curIdx);
        curIdx--;
        [array[curIdx], array[randIdx]] = [array[randIdx], array[curIdx]]
      }
      return array
    },
    randomizeCardOrder: function(spacing, offset = 0) {
      // returns a copy of flip tile and click tile data, in a random order, with click tiles every 'spacing'
      //   flip tiles

      // first we knuth shuffle the flip cards and click cards
      let shuffledArray = this.knuthShuffle(Array.from(importedCardData))
      let clickCards = this.knuthShuffle(Array.from(clickTileData))
      if (process.env.VUE_APP_NFT_AVAILABLE !== 'true') {
        // remove nft tile from clickCards if disabled in .env
        clickCards = clickCards.filter(obj => obj.clickId !== 'nft')
      }
      if (process.env.VUE_APP_VTO_AVAILABLE !== 'true') {
        // remove vto from clickCards if disabled in .env
        clickCards = clickCards.filter(obj => obj.clickId !== 'vto')
      }

      // finally we insert one click card in the shuffled order every 'spacing' tiles, repeating as necessary
      let clickIdx = 0
      const limit = shuffledArray.length + clickCards.length
      let lastTile = ''

      for (let i = 0; i < limit; i++) {
        if ( (i+offset) % spacing === 0) {
          let curId = clickCards[clickIdx].clickId
          // don't repeat click tiles
          if (curId === lastTile) {
            clickIdx++
          }

          shuffledArray = [...shuffledArray.slice(0,i), clickCards[clickIdx], ...shuffledArray.slice(i)]
          lastTile = clickCards[clickIdx].clickId
          clickIdx++;
        }
        // reset click index to go around again
        if (clickIdx >= clickCards.length) clickIdx = 0
      }

      return shuffledArray
    },
    handleQuizClose: function(e) {
      if (e.target.id !== 'quiz-container' || e.target.id !== 'quiz-close-btn') return
      else this.closeEverything()
    },
    handleTileClick: function(id) {
      this.openPanel = id
    },
    handleTileOpen: function(colorCode, rect) {
      this.currentBigCard = this.cardData.findIndex(obj => obj.colorCode === colorCode)
      this.zoomStartRect = rect
    },
    handleContainerScroll: function() {
      // track direction of scroll by saving previous scrollHeight and comparing on each scroll event
      // every (cardHeight + margin*2)px of scroll, move bottom cards in both rows to top or vice versa

      const el = document.querySelector('.appContainer')
      const row0 = document.querySelector('#cardRow0')
      const row1 = document.querySelector('#cardRow1')
      const cardHeight = row0.firstElementChild.getBoundingClientRect().height + (this.cardMargin*2)
      const upperThreshold = el.scrollHeight / 2 - cardHeight
      const lowerThreshold = el.scrollHeight / 2 + cardHeight

      // 'upper' and 'lower' here refers to the direction of scroll, that's why we do <= upper and >= lower
      if (el.scrollTop <= upperThreshold || el.scrollTop >= lowerThreshold) {
        let height = row0.firstElementChild.getBoundingClientRect().height
        if ((el.scrollTop - this.prevScrollPos) > 0) {
          console.log("scrolling down")
          // scrolling down, move cards from bottom to top
          let moveChild0 = row0.removeChild(row0.firstElementChild)
          let moveChild1 = row1.removeChild(row1.firstElementChild)
          if (moveChild0.childNodes.length <= 0 || moveChild1.childNodes.length <= 0) return
          row0.append(moveChild0)
          row1.append(moveChild1)

          // we're scrolling down and appended to the end, pushing the cards up, so scroll back down by cardHeight
          // only on Safari and Firefox
          if (navigator.userAgent.indexOf('Chrome') === -1) {
            el.scrollBy(0, -height)
          }

        } else {
          //console.log("scrolling up")
          // scrolling up, move cards from top to bottom
          let moveChild0 = row0.removeChild(row0.lastElementChild)
          let moveChild1 = row1.removeChild(row1.lastElementChild)
          if (moveChild0.childNodes.length <= 0 || moveChild1.childNodes.length <= 0) return
          row0.prepend(moveChild0)
          row1.prepend(moveChild1)
          // same as above, but opposite direction, still only Safari and Firefox
          if (navigator.userAgent.indexOf('Chrome') === -1) {
            el.scrollBy(0, height)
          }
        }
      }

      this.prevScrollPos = el.scrollTop

    },
    handleRowScroll: function(idx) {
      const row = document.getElementById(`cardRow${idx}`)

      // this is just for getting the cards' final width on the page
      let testNodeForWidth = document.getElementById(row.lastElementChild.id)
      let width = testNodeForWidth.getBoundingClientRect().width + this.cardMargin*2
      let scrollPartial = row.scrollLeft / (row.scrollWidth - width)

      if (scrollPartial < 0.2) {
        let width = row.lastElementChild.getBoundingClientRect().width + (this.cardMargin*2)
        let moveChild = row.removeChild(row.lastElementChild)
        if (moveChild.childNodes.length <= 0) return
        row.prepend(moveChild)
        row.scrollLeft += width

      } else if (scrollPartial > 0.6) {
        let width = row.firstElementChild.getBoundingClientRect().width + (this.cardMargin*2)
        let moveChild = row.removeChild(row.firstElementChild)
        if (moveChild.childNodes.length <= 0) return
        row.append(moveChild)
        row.scrollLeft -= width
      }
    },
  }
}
</script>

<style>
body {
  margin: 0;
}
</style>

<style scoped>
.scrollContainer {
  overflow-y: hidden;
}
.freezeScroll {
  overflow-y: hidden;
}
#quiz-close-btn {
  position: absolute;
  height: 50px;
  top: 10px;
  right: 0;
  z-index: 11;
}
.quizContainer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
}
.quizContainer > .quiz-viewport {
  position: fixed;
  width: 90vw;
  height: 90vh;
  left: calc(100vw/2 - 90vw/2);
  top: calc(100vh/2 - 90vh/2);
  box-shadow: 10px 10px 0 -4px black;
}
.title {
  color: white;
  text-transform: uppercase;
}
.splashTitle {
  font-weight: 700;
  font-family: "FoundersGroteskWeb-Bold", sans-serif;
  font-size: 1.35em;
}
.splashSubTitle {
  font-family: "FoundersGroteskWeb-Regular", sans-serif;
  font-weight: 400;
  font-size: 0.5em;
}
.cardRow {
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  margin: auto;
  /* Firefox hide scrollbar */
  scrollbar-width: none;
}
.cardRow::-webkit-scrollbar {
  /* Safari & Chrome hide scrollbar */
  display: none;
}
#cardRow1 {
  margin-top: -95px;
}
#backgroundSplash {
  backdrop-filter: blur(5px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5;
}
#flipbackground {
  width: 100%;
  height: 100%;
}
.appContainer {
  overflow-y: scroll;
  display: flex;
  width: 100vw;
  height: 100vh;
  /* background-color: #C3FA84; */
  background-image: url('~@/assets/images/Holo.jpg');
  background-size: cover;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>