<template>
    <div>
        <div v-if="!productEmpty" class="flex-none md:flex w-full">
            <div class="left-panel w-full md:w-1/2 text-center">
                <img class="m-auto p-2" :src="product.image" :alt="product.title">
            </div>
            <div class="right-panel w-full md:w-1/2 p-5 mt-10 text-left">
                <h1 class="font-bold text-2xl mb-3 uppercase">{{ product.title }}</h1>
                <div class="text-xl mb-3 uppercase">{{ product.subtitle }}</div>
<!--                <div class="mb-3">{{ product.description }}</div>-->
                <MVtoButton v-if="false" class="w-full md:w-2/3 mb-6 text-xl cursor-pointer" @clicked="showModal() && reinit()" :product="product"/>
                <div class="mb-1">6.3 oz / 307 ml</div>
                <div v-if="longHair" class="mb-3">We Recommend 2 Boxes</div>
                <div class="flex-none md:flex">
                    <!-- Quantity -->
                    <MQuantitySelector class="w-full md:w-1/3 mb-3 mr-3" :product="product" v-model="qty" />
                    <div class="w-full md:w-1/3">
                        <a class="text-center font-bold py-4 px-10 bg-black bg-white:hover text-black:hover uppercase text-white inline-block w-full border-black border-solid border" target="_blank" :href="isUlta ? product.ulta_url : productUrl">
                            {{ isUlta ? 'Take Me To It' : 'Add to Bag' }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            No data for product {{ product.id }}.
        </div>
        <OVtoModal v-show="displayModal" @close-modal='closeModal'/>
    </div>
</template>

<script>
  import {computed} from 'vue';
  import {useStore} from 'vuex';
  import MQuantitySelector from "./m-quantity-selector";
  import OVtoModal from "../organisms/o-vto-modal";
  import MVtoButton from "./m-vto-button";

  export default {
    name: "m-recommendation-info",
    components: {MVtoButton, OVtoModal, MQuantitySelector},
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        qty: 1,
        displayModal: false
      }
    },
    mounted() {
      this.emitter.on('ymk-close', () => {
        this.closeModal()
      })
    },
    setup() {
      const store = useStore();

      return {
        isUlta: computed(() => store.state.isUlta),
        answers: computed(() => Object.values(store.state.answers)),
      }
    },
    methods: {
      reinit() {

      },
      showModal() {
        this.displayModal = true
        if (window.YMK) {
          window.YMK.open()
        }
      },
      closeModal() {
        this.displayModal = false
      }
    },
    computed: {
      productEmpty() {
        return Object.keys(this.product).length <= 1;
      },
      productUrl () {
        return this.product.url + '?cart=' + this.product.variant_id + ':' + this.qty;
      },
      longHair() {
        return this.answers.includes('length_long') || this.answers.includes('length_super_long')
      }
    }
  }
</script>

<style scoped>

</style>
