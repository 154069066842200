<template>
  <svg
      width="314"
      height="120"
      viewBox="0 0 83.079165 31.75"
      version="1.1"
      id="svg5"
      inkscape:version="1.1.1 (c3084ef, 2021-09-22)"
      sodipodi:docname="unlocked_nft_button.svg"
      xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <g
        inkscape:label="black"
        inkscape:groupmode="layer"
        id="layer1">
      <path
          style="fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="M 6.6963116,6.3735796 82.227611,7.1077324 72.720769,26.687505 2.470735,20.203379 Z"
          id="path857"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer2"
        inkscape:label="purple">
      <path
          style="fill:#f00073;fill-opacity:1;stroke:#000000;stroke-width:0.661458;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
          d="M 80.091558,4.1670487 6.8082142,5.2801665 1.0081391,19.943292 72.130664,22.926743 Z"
          id="path1145"
          sodipodi:nodetypes="ccccc" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer4"
        inkscape:label="white">
      <path
          style="fill:none;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 1.7488735,21.127529 68.2473935,5.471093 -1.223614,2.096091 z"
          id="path1596" />
      <path
          style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
          d="m 1.9009405,20.758037 66.5262755,5.771444 -1.357355,2.679502 z"
          id="path6935" />
    </g>
    <g
        inkscape:groupmode="layer"
        id="layer3"
        inkscape:label="text">
      <text
          xml:space="preserve"
          style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.76111px;line-height:1.25;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
          x="26.147936"
          y="14.318716"
          id="text3458"
          transform="rotate(0.57230374)"><tspan
         sodipodi:role="line"
         style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:7.76111px;font-family:'Founders Grotesk';-inkscape-font-specification:'Founders Grotesk Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583;stroke-opacity:1"
         x="26.147936"
         y="14.318716"
         id="tspan24132">Buy Now</tspan></text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "UnlockedNftButton"
}
</script>