<template>
    <Quiz/>
</template>

<script>
  import Quiz from "./views/Quiz";

  export default {
    name: "AppQuiz",
    components: {
      Quiz
    }
  }
</script>

<style lang="scss" scoped>

</style>
