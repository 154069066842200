<template>
  <Microsite v-if="screenWidth > 800" />
  <MobileMicrosite v-else />
</template>

<script>
import Microsite from './views/Microsite.vue'
import MobileMicrosite from "./views/MobileMicrosite";

export default {
  name: 'App',
  data: function() {
    return {
      screenWidth: 0
    }
  },
  methods: {
    onResize: function() {
      this.screenWidth = window.innerWidth
    }
  },
  mounted: function() {
    this.$nextTick( () => {
      window.addEventListener('resize', this.onResize)
      this.screenWidth = window.innerWidth
    })
  },
  beforeUnmount: function() {
    window.removeEventListener('resize', this.onResize)
  },
  components: {
    Microsite,
    MobileMicrosite
  }
}
</script>

<style>
</style>
