<template>
    <div :class="{...style}" :style="getStyleObject">
        <span :class="{'sr-only': this.image }">{{ data.alt }} </span>
        <div class="clearfix"></div>
    </div>
</template>

<script>
  //import AButton from "../atoms/a-button";
  export default {
    name: "m-button-tile",
    //components: {AButton},
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      image() {
        return this.data.image
      },
      background() {
        return this.data.background || "none"
      },
      fontWeight() {
        return this.data.font_weight || 'bold'
      },
      style() {
        return {
          [(this.data.style || this.$parent.question.style || 'button')]: true,
          [this.$parent.question.columns || '']: true,
          [this.data.class || '']: true,
          [this.image ? 'has-image' : '']: true
        }
      },
      getStyleObject() {
        return {
          backgroundImage: this.image ? 'url(' + this.image + ')' : null,
          backgroundColor: this.background,
          fontWeight: this.fontWeight,
          //fontSize: this.image ? '0px' : null
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .shared {

    }

    .button {
        cursor: url("../../assets/cursor.png"), pointer;
        font-family: 'Founders Grotesk', sans-serif;
        font-weight: 700;
        height: 3.5rem;
        width: 100%;
        background-color: #000;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        border-radius: 2px;
        margin-bottom: 1.3rem;
        line-height: 1.4rem;
        font-size: 0.9rem;
        @media (min-width: 1024px) {
            font-size: 1rem;
        }

        &.invert {
            background-color: #FFF;
            color: #000;
        }

        &.selected, &:hover {
            background-color: #FFF !important;
            border-color: #000;
            border-width: 2px;
            color: #000 !important;

            &.invert {
                background-color: #000 !important;
                border-color: #fff;
                color: #fff !important;
            }
        }

        span {
            display: flex;
            align-items: center; /* Vertical */
            justify-content: center; /* Horizontal */
            height: 100%;
            cursor: url("../../assets/cursor.png"), pointer;
            padding: 1.5rem;
            text-align: left;
        }
    }

    .block {
        cursor: url("../../assets/cursor.png"), pointer;
        position: relative;
        font-family: 'Founders Grotesk', sans-serif;
        font-weight: 700;
        font-size: 1rem;
        @media (min-width: 1024px) {
            font-size: 1.5rem;
            max-width: 30%;
        }
        width: 100%;
        text-align: left;
        vertical-align: middle;
        background-color: #000;
        color: #fff;
        text-transform: uppercase;
        max-width: 46%;
        margin: 5px;
        flex: 1 0 auto;
        padding: 1rem;
        //height: auto;
        aspect-ratio: 1/1;
        //word-break: break-word;

        &.two-col {
            max-width: 46%;
            margin: 5px;
        }

        &.has-image {
            padding:0 !important;
            &:hover:before, &.selected:before {
                border: none;
                padding: 0;
                width: 100%;
                height: 100%;

                @media (min-width: 1024px) {
                    border: 0.5rem solid white;
                }
            }
        }

        &:before {
            content: '';
            float: left;
            padding-top: 100%;
        }

        &.selected, &:hover {
            background-color: #FFF !important;
            border-color: #000;
            border-width: 2px;
            color: #000 !important;
        }

        span {
            //display: flex;
            //align-items: center; /* Vertical */
            //justify-content: center; /* Horizontal */
            cursor: url("../../assets/cursor.png"), pointer;
            //padding: 1.5rem;
            font-size: 4.5vw;
            line-height: 7vw;
            text-align: left;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 90%;
            @media (min-width: 1024px) {
                font-size: 1.7vw;
                line-height: 2.3vw;
            }

            @media only screen and (min-width: 1200px) {
                //font-size: 2vw;
                //line-height: 3vw;
            }
        }
    }
</style>
