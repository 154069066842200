<template>
    <div>
        <div class="header-wrap">
            <header>
                <span class="backBtn" v-on:click="resetQuiz">
                    <i class="fas fa-fw fa-arrow-left"></i> Do the Quiz Again
                </span>
                <h1 class="title">
                    <slot name="header">Meet Your Match</slot>
                </h1>
                <span class="subtitle w-full md:w-2/3 m-auto block px-3">
                   <slot name="subtitle">Now that we know a little about you and your hair-story, let’s find your perfect shade.</slot>
                </span>
            </header>
        </div>
        <div class="edge"></div>
    </div>
</template>

<script>
  import {useStore} from 'vuex';
  export default {
    name: "m-header",
    setup() {
      const store = useStore();
      return {
        resetQuiz: () => store.commit('resetQuiz'),
      }
    },
  }
</script>

<style lang="scss" scoped>
    .header-wrap {
        width: 100vw;
    }
    header {
        color: #000000;
        text-transform: uppercase;
        padding-bottom: 3rem;
        text-align: center;

        .backBtn {
            display: block;
            margin: 0 1rem 1rem;
            width: fit-content;
            padding: 20px 10px 0 10px;
            cursor: pointer;
            font-weight: 600;
        }

        h1 {
            text-align: center;
            font-size: 3rem;
            font-family: 'Founders Grotesk', sans-serif;
            font-weight: 700;
        }

        .subtitle {
            font-size: 1.2rem;
            font-family: 'Maax Mono', sans-serif;
            //word-spacing: 0.3rem;
            //letter-spacing: 1px;
        }
    }
</style>
