<template>
  <div id="dummyBlur" @click.prevent="handleBgClick"></div>
  <div class="container">
    <div class="vtoContainer">
      <div class="loading">
        <span class="text">Loading Try-On...</span>
      </div>
      <MVtoTile />
    </div>
    <div class="shadeSelector" v-if="isDesktop">
      <h2>IGK's LIVE COLOR TRY-ON</h2>
      <h3>What's Your Natural Hair Color?</h3>
      <div class="shadeTabContainer">
        <button @click.prevent="scrollTabs(-1)">⬅</button>
        <div id="shadeTabs">
          <button
              v-for="(fam, idx) in Object.keys(shadeGroupings)"
              class="groupButton"
              :class="isButtonSelected(fam)"
              :key="idx"
              @click.prevent="handleShadeFamilySwap(fam)"
          >
            {{fam}}
          </button>
        </div>
        <button @click.prevent="scrollTabs(1)">⮕</button>
      </div>
      <h4 class="shadeTitle">
        {{selectedSwatch.title}}
        <span v-if="Object.keys(selectedSwatch).length">-</span>
        {{selectedSwatch.subtitle}}
      </h4>
      <div id="shadeSwatches">
        <img
            class="swatch"
            v-for="swatch in groupedSwatches"
            :src="swatch.src"
            :key="swatch.colorCode"
            @click="handleSwatchClick(swatch)"
        />
      </div>
      <span class="errorMsg" v-if="needToSelect">Select a shade to buy!</span>
      <UnlockedNftButton v-if="verifiedEmail" class="nftUnlockBtn" @click="handleBuyClick" />
      <UnlockNftButton v-else class="nftUnlockBtn" @click="handleBuyClick" />
      <!--
      <svg id="nftUnlockBtn" @click="handleBuyClick">
        <image v-if="verifiedEmail" href="@/assets/buttons/unlocked_nft_button.svg"></image>
        <image v-else href="@/assets/buttons/unlock_nft_button.svg"></image>
      </svg>
      -->
    </div>
    <div class="shadeSelectorMobile" v-else>
      <div id="shadeSwatchesMobile">
        <img
            class="swatch"
            v-for="swatch in allSwatches"
            :src="swatch.src"
            :key="swatch.colorCode"
            @click="handleSwatchClick(swatch)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MVtoTile from '@/components/molecules/m-vto-tile.vue'
import productMap from '@/../src/product-map.json'
import UnlockNftButton from '@/assets/buttons/unlock_nft_button.vue'
import UnlockedNftButton from '@/assets/buttons/unlocked_nft_button.vue'

export default {
  name: "OVtoPopup",
  components: {MVtoTile, UnlockNftButton, UnlockedNftButton},
  props: ["verifiedEmail"],
  emits: ["close", "openNftUnlock", "clickBuyBtn"],
  data: function() {
    return {
      shadeGroupings: {
        "Black": ['1B', '2N'],
        "Dark Brown": ['1B', '2N', '4N', '5N', '5GA'],
        "Light Brown": ['1B', '2N', '4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6AG', '6R', '6C', '6G', '7N', '7VG', '7G'],
        "Dark Blonde": ['4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6AG', '6R', '6C', '6G', '7N', '7VG', '7G', '7C', '8N', '8BG'],
        "Blonde": ['5N', '5GA', '6N', '6R', '6C', '6G', '7N', '7VG', '7G', '7C', '8N', '8BG', '9N', '9NA', '9GB'],
        "Light Blonde": ['5N', '6R', '6C', '7N', '7VG', '7G', '7C', '8N', '8BG', '9N', '9NA', '9GB', 'RG'],
        "Auburn": ['1B', '2N', '4N', '5N', '5GA', '5VM', '5G', '5GM', '6N', '6R', '6C', '6G', '7C'],
        "White": ['7VG', '7G', '8N', '8BG', '9N', '9GB', 'V', 'RG']
      },
      closedEvt: null,
      selectedShadeFamily: 'Light Brown',
      selectedSwatch: {},
      isDesktop: true,
      needToSelect: false
    }
  },
  beforeMount: function() {
    this.isDesktop = window.innerWidth >= 1024
  },
  mounted: function() {
    window.YMKRunner = window.YMKRunner || [];
    window.YMKRunner.push(() => {
      this.closedEvt = window.YMK.addEventListener('closed', () => {
        this.$emit('close')
      })
    })
    let tabsEl = document.getElementById("shadeTabs")
    if (tabsEl) tabsEl.scrollLeft += 20;
  },
  beforeUnMount: function() {
    if (this.closedEvt && window.YMK) {
      window.YMK.removeEventListener(this.closedEvt)
    }
  },
  computed: {
    allSwatches: function() {
      return Object.keys(productMap).map(key => {
        return {
          colorCode: key,
          src: require(`@/assets/swatches/${key}.jpg`),
          ...productMap[key]
        }
      })
    },
    groupedSwatches: function() {
      return this.allSwatches.filter(swatch => {
        return (this.shadeGroupings[this.selectedShadeFamily].includes(swatch.colorCode))
        //return swatch.shade_family === this.selectedShadeFamily
      })
    }
  },
  methods: {
    handleBuyClick: function() {
      this.needToSelect = false
      if (!this.verifiedEmail) {
        this.$emit('openNftUnlock')
      } else {
        if (!this.selectedSwatch || !this.selectedSwatch.colorCode) {
          this.needToSelect = true
        } else {
          this.$emit('clickBuyBtn', this.selectedSwatch.colorCode)
        }
      }
    },
    scrollTabs: function(dir) {
      let el = document.getElementById('shadeTabs')
      el.scrollLeft += (el.offsetWidth * dir)
    },
    handleShadeFamilySwap: function(family) {
      this.needToSelect = false
      this.selectedShadeFamily = family;
      this.selectedSwatch = {}
    },
    handleSwatchClick: function(swatch) {
      this.needToSelect = false
      this.selectedSwatch = swatch
      if (window.YMK && typeof window.YMK.applyMakeupBySku === 'function') {
        window.YMK.applyMakeupBySku(swatch.sku)
      }
    },
    handleBgClick: function() {
      this.needToSelect = false
      this.$emit('close')
    },
    isButtonSelected: function(fam) {
      if (fam === this.selectedShadeFamily) {
        return 'selected'
      } else return ''
    }
  }
}
</script>

<style scoped>
.errorMsg {
  color: darkred;
  font-family: "Founders Grotesk", sans-serif;
  font-size: 12px;
  margin: -21px auto 3px;
}
#unlockTitle {
  margin-top: auto;
}
.nftUnlockBtn {
  cursor: pointer;
  transform: scale(0.83);
  margin: -24px auto 0 auto;
}
.shadeTitle {
  padding: 11px 0 13px;
  min-height: 45px;
  max-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shadeTitle > span {
  margin: 0 5px;
}
#shadeSwatches {
  display: flex;
  flex-wrap: wrap;
  min-height: 240px;
  max-height: 240px;
  align-content: flex-start;
}
#shadeSwatchesMobile {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  background-color: #FFF343;
  padding-top: 12px;
}
.swatch {
  cursor: pointer;
  border: 4px solid black;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  width: 46px;
  height: 46px;
  object-fit: contain;
  margin: 0 8px 12px 8px;
}
/*
.swatch:first-child, .swatch:nth-child(6n) {
  margin-left: 8px;
  margin-right: 6px;
}
.swatch:nth-child(5n) {
  margin-left: 6px;
  margin-right: 8px;
}

 */
.shadeTabContainer {
  display: flex;
  flex-wrap: nowrap;
}
#shadeTabs {
  display: flex;
  justify-content: space-between;
  min-height: 35px;
  margin: 2px 9px 0;
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
}
#shadeTabs::-webkit-scrollbar {
  /* Safari & Chrome hide scrollbar */
  display: none;
}
.groupButton {
  font-weight: bold;
  border-bottom: 5px solid transparent;
  margin: 0 10px;
  white-space: nowrap;
  scroll-snap-type: x proximity;
}
.selected {
  border-bottom: 5px solid black;
}
.container {
  box-sizing: content-box;
  z-index: 3;
  position: absolute;
  border: 2px solid black;
  /* VTO Tile is fixed at 360x480 */
  width: 720px;
  height: 480px;
  left: calc(100vw/2 - 720px/2);
  top: calc(100vh/2 - 480px/2);
  display: flex;
}
@media screen and (max-width: 1023px) {
  .container {
    border: none;
    width: 100vw;
    height: fit-content;
    top: 2.5%;
    left: 0%;
    display: flex;
    flex-direction: column;
  }
}
.vtoContainer {
  width: 360px;
  background-color: #dbdbdb;
}
@media screen and (max-width: 1023px) {
  .vtoContainer {
    width: 100%;
    height: 412px;
  }
}
.shadeSelector {
  width: 360px;
  background-color: #FFF343;
  padding: 0 23px;
  display: flex;
  flex-direction: column;
}
.shadeSelector > h2 {
  font-size: 1.6em;
  font-weight: bold;
  margin-top: 10px;
}
.shadeSelector > h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
}
#dummyBlur {
  /* empty element to blur the whole background */
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  backdrop-filter: blur(5px)
}
.loading {
  position: fixed;
  top: 48%;
  left: 32%;
}
@media screen and (max-width: 1023px) {
  .loading {
    top: 33%;
    left: 34%;
  }
}
</style>
