<template>
    <div class="product-tile text-center mb-4 bg-white">
        <div v-show="Object.keys(product).length">
            <img v-on:click="setProduct" :src="product.image">
            <div class="main-text mb-6" v-on:click="setProduct">
                <div class="font-bold uppercase mb-1">{{ product.title }}</div>
                <div class="subtitle text-gray">{{ product.subtitle }}</div>
            </div>
            <div class="actions">
                <a v-if="!isUlta" class="button" :href="productUrl"><span>Add to Cart</span><span>$28</span></a>
                <a v-else class="button" target="_blank" :href="product.ulta_url"><span>Take Me To It</span><span>$28</span></a>
            </div>
        </div>
    </div>
</template>

<script>
  import {useStore} from 'vuex';
  //import MImageTile from "./m-image-tile";
  export default {
    name: "m-recommendation-item",
    //components: {MImageTile},
    data: function () {
      return {
        displayText: "Test Me"
      }
    },
    setup() {
      const store = useStore();
      return {
        isUlta: store.state.isUlta
      }
    },
    props: {
      product: {
        type: Object,
        required: true
      }
    },
    methods: {
      setProduct() {
       this.emitter.emit('setProduct', this.product.id)
      }
    },
    computed: {
      productUrl () {
        return this.product.url + '?cart=' + this.product.variant_id + ':' + 1;
      },
    }
  }
</script>

<style lang="scss" scoped>
    img {
        cursor: pointer;
        width: 80%;
        margin: 0 auto -2rem auto;
    }

    .button {
        display: inline-block;
        border: 1px solid black;
        padding: 0.5rem 1rem;
        box-shadow: -3px 3px 8px 3px #dbdbdb;

        span {
            display: inline-block;

            &:first-child {
                padding-right: 0.5rem;
                border-right: 1px solid black;
            }

            &:last-child {
                padding-left: 0.5rem;
            }
        }
    }
</style>
